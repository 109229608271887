import React, { useState, useEffect } from 'react'
import Booking from './Booking';
import {storage} from "./Firebase"
import {ref, uploadBytes} from "firebase/storage"
import { auth, provider } from './Firebase';
import {signInWithPopup} from "firebase/auth"

function Form(props) {

  const [showPopup, setShowPopup] = useState(false);
  const [showBtnContinue, setShowBtnContinue] = useState(true)
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [guests, setGuests] = useState(0);
  const [extraFee, setExtraFee] = useState(0)
  const [showForm, setShowForm] = useState(false)  
  const [newBalances, setNewBalances] = useState(props.balances)

// useEffect(()=>{
//   console.log("RULES: " + props.rulesApproval);
// },[props.rulesApproval])

  function checkForm(){
    console.log('Checking Form...');
    
    let areDatesRight = false;
    let isPhoneRight = false;
    let isEmailRight = false;
    let isNameRight = false
    let isLastNameRight = false

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    regex.test(email)  ? isEmailRight = true : isEmailRight = false
    phone.length == 10 ? isPhoneRight = true : isPhoneRight = false
    props.minDatesCheck ? areDatesRight = true : areDatesRight = false
    name.length >= 3 ? isNameRight = true : isNameRight = false
    lastName.length >= 3 ? isLastNameRight = true : isLastNameRight = false

    if(areDatesRight && isPhoneRight && isEmailRight && isNameRight && isLastNameRight && guests && props.rulesApproval > 0){
      setShowBtnContinue(true)
    } else {
      setShowBtnContinue(false)
    }
  }

  useEffect(()=>{
    checkForm()
  })



  function checkExtraGuests(){
    // console.log("Checking Extra Guests");
    let extra = guests - props.guestsIncluded; 
    let fee =  extra * props.extraGuestFee;
    if(fee > 0) {
      setExtraFee(fee)
      newBalances.push({date:"Extra Guest Fee:", price: fee})
    } else {
      setExtraFee(0)
      const updatedArray = newBalances.filter((item) => item.date !== "Extra Guest Fee:");
      setNewBalances(updatedArray);
    }
    setShowPopup(true)
  }

  const googleAuth = () => {
    signInWithPopup(auth, provider).then((data)=>{
      setEmail(data.user.email)
      setName(data.user.displayName.split(' ')[0])
      setLastName(data.user.displayName.split(' ')[1])
    })
  }

  useEffect(()=>{
    if(!name == '' && !email == ''){
      setShowForm(true)
    }
  },[name, email])

  function capitalizeString(str) {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
  
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  


  useEffect(()=>{
    setNewBalances(props.balances)
  })




  return (
    <div>
        <div className='form' style={{display: showForm? "block":"none"}}>    
            <h3> Number of Guests </h3>
            <div className='guestsNum'>
              <p> {guests} guests </p>
              <div className='guestsBtns'>
                <button onClick={()=> setGuests(guests-1)} style={{visibility: guests <= 1 ? "hidden":"visible"}}> <i className="bi bi-dash-lg"></i> </button>
                <button onClick={()=> setGuests(guests+1)}> <i className="bi bi-plus-lg"></i> </button>
              </div>
            </div>
            <h3> First Name </h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-calendar4-week"></i>  
              <input className="form-inputs capitalized" 
                type={"text"} 
                value={capitalizeString(name)}
                placeholder="First Name" 
                onChange={(e)=>setName(e.target.value)}
              />
            </div>
            <h3> Last Name </h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-calendar4-week"></i>  
              <input className="form-inputs capitalized" 
                type={"text"} 
                value={capitalizeString(lastName)}
                placeholder="Last Name"     
                onChange={(e)=>setLastName(e.target.value)}
              />
            </div>
            <h3> Phone Number </h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-calendar4-week"></i>  
              <input className="form-inputs" 
                type={"tel"} 
                placeholder="Phone Number"     
                onChange={(e)=>setPhone(e.target.value)}
              />
            </div>
            <h3> Email Address </h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-calendar4-week"></i>  
              <input className="form-inputs lowercased" 
                type={"email"} 
                value={email}
                readOnly
                placeholder="example@mail.com"     
                onChange={(e)=>setEmail(e.target.value)}
              />
            </div>
        </div>
        <div style={{display: showBtnContinue ? "block" : "none"}}>
          <button className="btnForm" onClick={()=>checkExtraGuests()}> Book Now </button>
        </div>
        <div className="popup-booking" style={{display : showPopup ? "grid" : "none"}}>
          {showPopup && (
            <Booking 
              propertyID={props.propertyID}
              propertyName={props.propertyName} 
              propertyTitle={props.propertyTitle}
              propertyPicture={props.propertyPicture} 
              propertyAddress={props.propertyAddress}
              name={name} 
              lastName={lastName} 
              email={email} 
              phone={phone} 
              checkIn={props.checkIn} 
              checkOut={props.checkOut} 
              balance={props.balance}
              balances={newBalances}
              selectedDates={props.selectedDates}
              closePopUp={()=>setShowPopup(false)}
              selectedID={props.selectedID}
              checkTimes={props.checkTimes}
              guests={guests}
              extraFee={extraFee}
              bedrooms={props.bedrooms}
              bathrooms={props.bathrooms}
            />
          )}
        </div>
        <div className='googleAuth' style={{display: showForm? "none":"flex"}}>
            <div className='googleSignIn' onClick={()=>googleAuth()}>
              <img className="google-icon" src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1706677126/IMG_0018_xlfgnj.png"/>
              <p> Book With Google</p>
            </div>
        </div>
    </div>
  )
}

export default Form;
