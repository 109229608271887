import React, { useEffect, useState, createContext } from "react";

const PropertiesContext = createContext({
  properties: [],
  setProperties: () => {},
});

const PropertiesProvider = ({ children }) => {
  const apiURL = process.env.REACT_APP_APIURL;
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    getProperties();
  }, []);

  const getProperties = async () => {
    console.log("Getting Properties... ");

    fetch(apiURL + '/api/getProperties')
    .then(response => response.json())
    .then(response =>{
        setProperties(response.results)
        console.log(response.results);
        
    })
  };

  return (
    <PropertiesContext.Provider value={{ properties, setProperties }}>
      {children}
    </PropertiesContext.Provider>
  );
};

export { PropertiesContext, PropertiesProvider };
