import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

import Home from "./Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Rentals from './Rentals';
import Property from './Property';
import Services from './Services';
import Login from './Login';
import Contact from './Contact';
import Support from './Support';
import Terms from './Terms';
import Chat from './Chat';
import Reservation from './Reservation';
import PrintableReservation from './components/PrintableReservation';


import NewRentals from './pages/rentals'

import { PropertiesProvider } from './context/Properties';

function App() {
  return (
    <PropertiesProvider>
      <Router>
        <div className="App">
          <Header />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/property" element={<Property />} />
              <Route path="/services" element={<Services />} />
              <Route path="/rentals" element={<NewRentals />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/support" element={<Support />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/reservation" element={<Reservation />} />
              <Route path="/printable-reservation" element={<PrintableReservation />} />


              {/* NEW PAGES */}
              <Route path="/new-rentals" element={<NewRentals />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </PropertiesProvider>
  );
}

export default App;
